import React from "react"
import styled from "styled-components"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { PhoneIcon, PrefabricatedIcon, FastDeliveryIcon, YearWarrantyIcon, BestBusinessIcon } from "../../components/Icons"
import { Section, Container, ContainerFluid, SectionTitle, SectionDescription, SectionImageBkg } from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"


const CustomBuilding = styled.div`
  margin:0;
`
const CustomBuildingFigure = styled.div`
  position:relative;
  .gatsby-image-wrapper{
    display:block;
  }
`
const CustomBuildingCaption = styled.div`
  background-color:#fff;
  padding:26px 20px;
  .container{
    padding:0;
  }
`
const CustomBuildingInner = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Montserrat', sans-serif;
  font-weight:600;
  margin:0 -10px;

  flex-direction:column;
  font-size: 24px;
  line-height: 34px;
  text-align:center;
  @media (min-width: 992px) {
    flex-direction:row;
    text-align:left;
  }
  @media (min-width: 1024px) {
    font-size: 26px;
    line-height: 36px;
  }
  @media (min-width: 1200px) {
    font-size: 34px;
    line-height: 44px;
  }
  @media (min-width: 1600px) {
    font-size: 40px;
    line-height: 50px;
  }
  strong, a{
    margin:0 10px;
  }
  strong{
    font-weight:600;
    text-transform:uppercase;
    @media (max-width: 991.99px) {
      margin-bottom:15px;
    }
  }
  .btn{
    display:flex;
  }
`

const ListService = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items: end;
  width: 100%;
  max-width:1410px;
  margin-bottom:30px;
  @media(min-width:992px){
    position: absolute;
    z-index:1;
    transform: translateX(-50%);
    left: 50%;
    bottom:15px;
    margin-bottom:0;
  }
  @media(min-width:1200px){
    bottom:60px;
  }
`
const ListItemService = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media(min-width:576px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(min-width:992px){
    padding:0 20px;
    flex: 0 0 25%;
    max-width: 25%;
  }
`
const CardBordered = styled.div`
  position:relative;
  border-radius:15px 15px 0 0;
  overflow:hidden;
  margin-bottom:30px;
  border:2px solid #C20000;
  padding-top: 60px;
  @media(min-width:992px){
    padding-top: 80px;
    border-color:#161420;
  }
  cursor:pointer;
  .card-icon{
    transition: 0.5s;
  }
  .card-caption{
    > p{
      transition: 0.8s;
    }
  }
  &:hover{
    .card-icon{
      transform: translateY(-20px);
    }
    .card-caption{
      > p{
        transform: translateY(-20px);
      }
      &:after{
        transform: translateY(0);
        min-height: 100%;
        opacity:0.8;

        background-color: rgba(194,0,0,0.8);
        backdrop-filter: blur(6px);
        
      }
    }
  }

`
const CardIcon = styled.div`
  width:70px;
  height:70px;
  background-color:#fff;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  box-shadow:0 0 10px rgba(22,20,32,0.2);
  position:relative;
  transition: 0.5s;
`
const CardCaption = styled.div`
  color:#fff;
  text-align:center;
  display:flex;
  align-items:end;
  justify-content:center;
  padding:10px;
  
  &:after{
    content: '';
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    min-height: 100px;
    transform: translateY(0);
    transition: 0.5s;
    background-color:#C20000;
    @media(min-width:992px){
      background-color:#161420;
    }
  }
  > p{
    position:relative;
    z-index:1;
    margin-bottom:0;
    font-size:16px;
    line-height:28px; 
    font-weight:500;
    min-height:70px;
    display: flex;
    align-items: center;
    @media(min-width:1200px){
      font-size:18px;
      line-height:30px; 
    }
    @media(min-width:1600px){
      font-size:20px;
      line-height:32px; 
    }
  }
`
const CustomBuildingSection = ({ data }) => {
	return(
    <Section ept="120px" epb="120px" xpt="80px" xpb="80px" pt="40px" pb="40px" bgColor="#131413"  className="section-buy">
      <SectionImageBkg opacity="0.02"><StaticImage src="../../images/line-vector.png" alt="line-vector" /></SectionImageBkg>
      <Container>
        <SectionTitle color="#fff" textAlign="center"><span className="stroke-white">{data.prefix}</span> {data.title}</SectionTitle>
        <SectionDescription color="#fff" mb="30px" textAlign="center">
        <div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
        </SectionDescription>        
      </Container>
      <ContainerFluid>
        <CustomBuilding>
            <CustomBuildingFigure>
              <ListService>
                <ListItemService>
                  <CardBordered>
                    <CardIcon className="card-icon"><PrefabricatedIcon /></CardIcon>
                    <CardCaption className="card-caption"><p>{data.features[0].content}</p></CardCaption>
                  </CardBordered>
                </ListItemService>  

                <ListItemService>
                  <CardBordered>
                  <CardIcon className="card-icon"><FastDeliveryIcon /></CardIcon>
                  <CardCaption className="card-caption"><p>{data.features[1].content}</p></CardCaption>
                  </CardBordered>
                </ListItemService>  

                <ListItemService>
                  <CardBordered>
                  <CardIcon className="card-icon"><YearWarrantyIcon /></CardIcon>
                  <CardCaption className="card-caption"><p>{data.features[2].content}</p></CardCaption>
                  </CardBordered>
                </ListItemService>  

                <ListItemService>
                  <CardBordered>
                  <CardIcon className="card-icon"><BestBusinessIcon /></CardIcon>
                  <CardCaption className="card-caption"><p>{data.features[3].content}</p></CardCaption>
                  </CardBordered>
                </ListItemService>  
              </ListService>
              <GatsbyImage image={data.image.gatsbyImageData} alt="why panhandle" />
            </CustomBuildingFigure>
        </CustomBuilding>
        <CustomBuildingCaption>
          <Container className="container" maxWidth="1370px">
            <CustomBuildingInner>
              <strong>{data.ctaText}</strong>
              <a href="tel:8067314815" aria-label="Phone Number"><PrimaryButton text="(806) 731-4815" iconAfter={<PhoneIcon />} /></a>
            </CustomBuildingInner>
          </Container>
        </CustomBuildingCaption>
      </ContainerFluid>
    </Section>
	)
}
export default CustomBuildingSection
